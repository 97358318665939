import Joi from "joi"

import { SourceUrlValidator } from "../../utilities/customValidators"

export const SCHEMA = Joi.object({
  baseUrl: SourceUrlValidator.sourceURL()
    .required()
    .label("Base URL")
    .messages({
      "string.empty": "Base URL is required",
      "any.required": "Base URL is required",
      "string.pattern.base": "Base URL must be a valid URL e.g. https://example.com",
    })
    .options({ abortEarly: true }),
  state: Joi.string().valid("unlisted", "private", "published").required(),
}).required()

import Joi from "joi"

import { SourceUrlValidator } from "../../utilities/customValidators"

export const SCHEMA = Joi.object({
  group: Joi.string()
    .min(2)
    .max(64)
    .pattern(/^[a-z0-9_]{2,64}$/)
    .required()
    .messages({
      "string.pattern.base":
        "Group name can contain lowercase letters a-z, numbers 0-9, and underscores (_) and be 2-64 characters long",
      "string.empty": "Group is required",
      "any.required": "Group is required",
      "string.min": "Group must be at least 2 characters long",
      "string.max": "Group length must be less than or equal to 64 characters long",
    })
    .options({ abortEarly: true }),
  definition: Joi.string().required().messages({
    "string.empty": "Data product definition is required",
    "any.required": "Data product definition is required",
  }),
  variant: Joi.string()
    .min(2)
    .max(64)
    .pattern(/^[a-z0-9_]{2,64}$/)
    .messages({
      "string.pattern.base":
        "Variant is optional (can be left empty) and can contain lowercase letters a-z, numbers 0-9, and underscores (_) and be 2-64 characters long",
      "string.min":
        "Variant is optional (can be left empty) but when provided, it must be at least 2 characters long",
      "string.max":
        "Variant length is optional (can be left empty) but when provided, it must be less than or equal to 64 characters long",
    })
    .allow("")
    .options({ abortEarly: true }),
  baseUrl: SourceUrlValidator.sourceURL()
    .required()
    .label("Base URL")
    .messages({
      "string.empty": "Base URL is required",
      "any.required": "Base URL is required",
      "string.pattern.base": "Base URL must be a valid URL e.g. https://example.com",
    })
    .options({ abortEarly: true }),
  state: Joi.string().valid("unlisted", "private", "published").required(),
}).required()
